import React from "react";
import "./Footer.css";
import DZText from "../text/DZText";
import { NavLink, useNavigate } from "react-router-dom";
import { ROUTES } from "../../reactRoute/RouteConstants";
import { useLinksManager } from "../../lib/customHooks/useLinksManager";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const currentDay = new Date().toLocaleDateString("en-US", { weekday: "long" }).toLocaleLowerCase();
  const { faceBookLink, instagramLink, linkedInLink, twitterLink, restaurantDetails, opening_hours } = useLinksManager();
  const { mon_from, mon_to, tue_from, tue_to, wed_from, wed_to, thu_from, thu_to, fri_from, fri_to, sat_from, sat_to, sun_from, sun_to } = opening_hours ?? {};


  const fnShowOpeningHours = (from, to, dayName, closedText) => {
    const hoursContainer = 'd-flex align-center space-between w-full';
    const hoursActiveContainer = 'd-flex align-center space-between w-full green-back p-2-4 radi-4';
    const hoursClassName = ` ${currentDay == dayName && 'white-color'} w-full text-start p-2-2 dark-color radi-4 fs-16 dark-color fw-500 `;
    return (
      <>
        {from && to ?
          <div className={currentDay == dayName ? hoursActiveContainer : hoursContainer}>
            <DZText className={hoursClassName} children={`${t('Footer.' + dayName)}`} />
            <DZText className={hoursClassName + ' text-end'} children={`${from} - ${to}`} />
          </div>
          : <div className={currentDay == dayName ? hoursActiveContainer : hoursContainer}>
            <DZText className={hoursClassName} children={`${t('Footer.' + dayName)}`} />
            <DZText className={hoursClassName + ' text-end'} children={`${t('Footer.' + closedText)}`} />
          </div>
        }
      </>
    );
  };

  return (
    <footer className="d-flex flex-col align-center justify-center">

      <div className="max-inner w-full d-flex space-between p-36-0 sm-flex-wrap sm-gap-40 sm-align-center sm-justify-center sm-flex-col">

        <div className="d-flex flex-col gap-32 sm-align-center">

          <img src="./images/logo.jpg" className="w-120" alt="" />

          <div className="d-flex flex-col gap-12">
            {restaurantDetails?.data?.phone && (
              <a href={`tel:${restaurantDetails?.data?.phone}`}>
                <span className="fs-16 dark-color fw-500 sm-text-center cursor-p hover-green transition-03">
                  <DZText children={"Common.phone"} />:{" "}
                  {restaurantDetails?.data?.phone}
                </span>
              </a>
            )}

            {restaurantDetails?.data?.email && (
              <a href={`mailto:${restaurantDetails?.data?.email}`}>
                <span className="fs-16 dark-color fw-500 sm-text-center cursor-p hover-green transition-03">
                  <DZText children={"Common.email"} />:{" "}
                  {restaurantDetails?.data?.email}
                </span>
              </a>
            )}
            {restaurantDetails?.data?.address && (
              <a
                href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                  restaurantDetails?.data?.address
                )}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="fs-16 dark-color fw-500 sm-text-center cursor-p hover-green transition-03">
                  <DZText children={"Common.address"} /> :{" "}
                  {restaurantDetails?.data?.address}
                </span>
              </a>
            )}
          </div>

          <div className="d-flex gap-20">
            {twitterLink && (twitterLink !== 'null') && (
              <a href={twitterLink} target="_blank">
                <i className="fa-brands fa-x-twitter fw-600 hover-white hover-backGreen cursor-p transition-03 dark-color fs-18 w-50 h-50 d-flex align-center justify-center full-radi light-back hover-fw400"></i>
              </a>
            )}
            {instagramLink && (instagramLink !== 'null') && (
              <a href={instagramLink} target="_blank">
                <i className="fa-brands fa-instagram fw-600 hover-white hover-backGreen cursor-p transition-03 dark-color fs-18 w-50 h-50 d-flex align-center justify-center full-radi light-back hover-fw400"></i>
              </a>
            )}
            {faceBookLink && (faceBookLink !== 'null') && (
              <a href={faceBookLink} target="_blank">
                <i className="fa-brands fa-facebook-f fw-600 hover-white hover-backGreen cursor-p transition-03 dark-color fs-18 w-50 h-50 d-flex align-center justify-center full-radi light-back hover-fw400"></i>
              </a>
            )}
            {linkedInLink && (linkedInLink !== 'null') && (
              <a href={linkedInLink} target="_blank">
                <i className="fa-brands fa-linkedin-in fw-600 hover-white hover-backGreen cursor-p transition-03 dark-color fs-18 w-50 h-50 d-flex align-center justify-center full-radi light-back hover-fw400"></i>
              </a>
            )}
          </div>
        </div>

        <div className="d-flex flex-col gap-32 sm-align-center">
          <span className="fs-18 fw-600">
            <DZText upperCase >{"Footer.information"}</DZText>
          </span>

          <div className="d-flex flex-col gap-12">
            <span
              onClick={() => navigate(ROUTES.about)}
              className="fs-16 dark-color fw-500 sm-text-center cursor-p hover-green transition-03"
            >
              <DZText>{"Footer.about_us"}</DZText>
            </span>

            <span
              onClick={() => navigate(ROUTES.cart)}
              className="fs-16 dark-color fw-500 sm-text-center cursor-p hover-green transition-03"
            >
              <DZText>{"Footer.check_out"}</DZText>
            </span>

            <span
              onClick={() => navigate(ROUTES.contact)}
              className="fs-16 dark-color fw-500 sm-text-center cursor-p hover-green transition-03"
            >
              <DZText>{"Footer.contact"}</DZText>
            </span>
          </div>
        </div>

        <div className="d-flex flex-col gap-32 sm-align-center" >

          <span className="fs-18 fw-600">
            <DZText children={'Footer.opening_hours'} />
          </span>

          <div className='d-flex align-center flex-col gap-4 min-w-240'>

            {fnShowOpeningHours(mon_from, mon_to, 'monday', 'closed')}
            {fnShowOpeningHours(tue_from, tue_to, 'tuesday', 'closed')}
            {fnShowOpeningHours(wed_from, wed_to, 'wednesday', 'closed')}
            {fnShowOpeningHours(thu_from, thu_to, 'thursday', 'closed')}
            {fnShowOpeningHours(fri_from, fri_to, 'friday', 'closed')}
            {fnShowOpeningHours(sat_from, sat_to, 'saturday', 'closed')}
            {fnShowOpeningHours(sun_from, sun_to, 'sunday', 'closed')}

          </div>

        </div>

        <div className="d-flex flex-col gap-32 sm-align-center">
          <span className="fs-18 fw-600">
            <DZText upperCase children={'Footer.my_account'} />
          </span>

          <div className="d-flex flex-col gap-12">
            <span className="fs-16 dark-color fw-500 sm-text-center cursor-p hover-green transition-03">
              <DZText>{"Footer.my_account"}</DZText>
            </span>

              <NavLink to={ROUTES.cart}>
                <span className="fs-16 dark-color fw-500 sm-text-center cursor-p hover-green transition-03">    
                  <DZText>{"Footer.shop_cart"}</DZText>
                </span>
              </NavLink>

            {/* <span className="fs-16 dark-color fw-500 sm-text-center cursor-p hover-green transition-03">
              <DZText>{"Footer.tracking_order"}</DZText>
            </span> */}
          </div>
        </div>

      </div>

      <span className="text-center fs-18 fw-500 white-color w-full p-16-0 green-back sm-fs-14">
        Copyright © 2024 - All Rights Reserved.
      </span>

    </footer>
  );
};

export default Footer;