import React from "react";
import ShoPage from "../pages/shoPage/ShoPage";
import ContactPage from "../pages/contactPage/ContactPage.jsx";
import AboutPage from "../pages/aboutPage/AboutPage.jsx";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Navbar from '../components/navbar/Navbar.jsx'
import Footer from "../components/footer/footer";
import { ROUTES } from "./RouteConstants";
import Cart from "../pages/cart/Cart";
import WishlistPage from "../pages/wishlist/WishlistPage.jsx";
import BlogPage from "../pages/blogPage/BlogPage.jsx";
import ProfilePage from "../pages/profilePage/ProfilePage.jsx";
import Home from "../pages/home/Home.jsx";
import Addresses from "../components/addressess/Addresses.jsx";
import OrderInfoProduct from "../components/orderInfoProduct/OrderInfoProduct.jsx";
import ServicePage from "../pages/servicePage/ServicePage.jsx";
import Gdpr from "../components/gdpr/Gdpr.js";

const ReactRoute = () => {
  return (
    <BrowserRouter>
      <Navbar />
      <Routes>
        <Route path={ROUTES.home} element={<Home />} />
        <Route path={ROUTES.shop} element={<ShoPage />} />
        <Route path={ROUTES.cart} element={<Cart />} />
        <Route path={ROUTES.contact} element={<ContactPage />} />
        <Route path={ROUTES.about} element={<AboutPage />} />
        <Route path={ROUTES.blog} element={<BlogPage />} />
        <Route path={ROUTES.wishlist} element={<WishlistPage />} />
        <Route path={ROUTES.profile} element={<ProfilePage />} />
        <Route path={ROUTES.services} element={<ServicePage />} />
        <Route path="*" element={
          <div className="d-flex align-center justify-center gap-12 flex-col h-4vh">
            <span className="fs-14 dark-color opacity-03">
              <h1>{'404 Not Found'}</h1>
            </span>
          </div>
        } />
      </Routes>
      <Gdpr />
      <Footer />
    </BrowserRouter>
  );
};

export default ReactRoute;
