import React from 'react'
import { ComingSoonLoader } from '../../components/loaders/Loaders'
import { useTranslation } from 'react-i18next';
import DZText from '../../components/text/DZText';

const ServicePage = () => {
    const { t } = useTranslation();
    const aboutUsDetail = t("Service.service_detail");
    const paragraphs = aboutUsDetail?.split('\n')?.map((paragraph, index) => (
        <p key={index} className="fs-16 dark-color">{paragraph}</p>
    ));
    return (
        <div className="d-flex flex-col align-center justify-center w-full">
            <div className="d-flex gap-24 max-inner align-center w-full p-48-0 md-flex-col">
                <div className="d-flex flex-col gap-32">
                    <DZText className="fs-36 fw-600 dark-color">
                        {"Service.service_title"}
                    </DZText>
                    {paragraphs}
                </div>
            </div>
        </div>
    )
}

export default ServicePage