export const ROUTES = {
    home: '/',
    shop: '/shop',
    contact: '/contact',
    faqs: '/faqs',
    services: '/services',
    about: '/about',
    cart: '/cart',
    blog: '/blog',
    wishlist: '/wishlist',
    profile: '/profile',
    addresses: '/addresses',
}