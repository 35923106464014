import React, { useEffect } from 'react'
import './ViewsStyling.css'

const ViewUserAddress = ({ fnSetAddressId, addressId, userAddressList, setUserInfo }) => {

    useEffect(() => {
        const address = userAddressList?.data?.find((item) => item.id == addressId);

        const city = address?.city;
        const postCode = address?.post_code;
        const street = address?.street;
        const lat = address?.lat;
        const long = address?.long;
        const user_address_id = address?.id;

        const item = { city, postCode, street, lat, long, user_address_id };

        setUserInfo((pre) => ({ ...pre, ...item }));
    }, [addressId]);

    return (
        <>
            <select
                id={addressId}
                className="p-8-10 outline-none border-grey dark-color w-full"
                style={{ width: '100%' }}
                onChange={(e) => {
                    const selectedOptionId = e.target.value;
                    const address = userAddressList?.data?.find((item) => item.id == selectedOptionId);

                    const city = address?.city;
                    const postCode = address?.post_code;
                    const street = address?.street;
                    const lat = address?.lat;
                    const long = address?.long;

                    const item = { city, postCode, street, lat, long };

                    setUserInfo((pre) => ({ ...pre, ...item }));

                    fnSetAddressId(e.target.value);
                }}
            >
                {userAddressList?.data?.length > 0 ?
                    userAddressList?.data?.map((item) => {
                        return (
                            <option key={item.id} value={item.id} selected={item.id == addressId}>
                                {item?.street}
                            </option>
                        );
                    }) : <option>{'No Address Yet'}</option>}
            </select>
        </>
    )
}

export default ViewUserAddress;