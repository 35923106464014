import React, { useEffect, useState } from "react";
import SnackBar from "../snackBar/SnackBar";
import { Config } from "../../constants/Index";
import InputField from "../InputField/InputField";
import { useDispatch } from "react-redux";
import { useSnackBarManager } from "../../lib/customHooks/useSnackBarManager";
import { BtnLoader } from "../loaders/Loaders";

const ConfirmationCodePopup = ({
  setLoginPopup,
  setForgetPasswordPopup = false,
  setConfirmationCodePopup = true,
  setNewPasswordPopup = false,
  userEmail = false,
  setUserVerificationCode = false,
}) => {
  const { fnShowSnackBar } = useSnackBarManager();
  const [data, setData] = useState({ verification_code: "" });
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        handleClosePopups();
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  function handleOpenPopups() {
    setForgetPasswordPopup(false);
    setNewPasswordPopup(true);
    setConfirmationCodePopup(false);
  }

  function handleClosePopups() {
    setLoginPopup(false);
    setConfirmationCodePopup(false);
    setForgetPasswordPopup(true);
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleConfirmationCode = async (e) => {
    e.preventDefault();
    if (data.verification_code && userEmail) {
      setLoader(true);
      const response = await fetch(
        `${Config.serverUrl}api/verify-email-forgot-password`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            verification_code: data?.verification_code,
            email: userEmail,
          }),
        }
      );

      if (response.ok) {
        const responseData = await response.json();
        if (responseData.success) {
          setUserVerificationCode(data.verification_code);
          handleOpenPopups();
        } else {
          fnShowSnackBar(responseData.message || "Code is incorrect!");
        }
      } else {
        fnShowSnackBar("Code is incorrect!");
      }
    } else {
      if (!data.verification_code) {
        fnShowSnackBar("Please enter your verification code!");
      }
    }
    setLoader(false);
  };

  return (
    <div className="d-flex flex-col align-center gap-24 position-r p-12 sm-max-w-300">
      <i
        onClick={() => handleClosePopups()}
        className="fa-solid fs-20 dark-color fa-xmark position-abs r-20 transition-03 hover-green cursor-p t-20"
      ></i>

      <h2 className="fs-32 fw-500 dark-color">Enter Verification Code</h2>

      <div className="d-flex align-start flex-col gap-12 w-420 sm-max-w-300 w-full">
        <span className="fs-14 dark-color">
          Verification Code
          <span className="fs-14 green-color fw-500">*</span>
        </span>

        <InputField
          placeholder="verification code..."
          type="text"
          onChange={handleInputChange}
          name="verification_code"
          value={data.verification_code}
        />
      </div>

      <span
        onClick={handleConfirmationCode}
        className="d-flex p-8-12 green-back white-color cursor-p align-center justify-center w-full"
      >
        {loader ? <BtnLoader /> : "Confirm Code"}
      </span>
    </div>
  );
};

export default ConfirmationCodePopup;
