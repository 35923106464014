import React, { useState, useEffect } from "react";
import "./inputField.css";

const InputField = ({  placeholder, type = "text", name, onChange, value, showPassword, togglePasswordVisibility, autofill, darkColor, className = " ", required = false, 
errorText = "Please enter a valid value",  disabled = false }) => {
  const [touched, setTouched] = useState(false);
  const [unFilled, setUnFilled] = useState(false);

  useEffect(() => {
    if (value) {
      setUnFilled(false);
    } else if (touched && !value && required) {
      setUnFilled(true);
    } else {
      setUnFilled(false);
    }
  }, [touched, value, required]);


  return (
    <>
      <div className={`input__container ${className}`}>
        <input
          value={value}
          onChange={onChange}
          disabled={disabled}
          onFocus={() => setTouched(true)}
          name={name}
          // className={`${darkColor ? "input__field__dark" : ""} ${(unFilled && required) && "red__color"
          //   } input__field`}
          className={`p-8-10 outline-none border-grey dark-color w-full`}
          type={showPassword ? "text" : type}
          placeholder={placeholder}
        // autoComplete={autofill}
        />
        {/* {type === "password" && (
          <i className={showPassword ? "ri-eye-off-fill" : 'ri-eye-fill '} onClick={togglePasswordVisibility} />
        )} */}
      </div>
      {unFilled && required && (
        <span className="error__message red__color">{errorText}</span>
      )}
    </>
  );
};

export default InputField;