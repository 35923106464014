import React from 'react'
import { KEYS, PRODUCT_SIZES, SORTING_TYPE } from '../../constants/Index';
import './ShoPage.css'
import { useShopPageManager } from '../../lib/customHooks/useShopPageManager';
import { Pagination, PaginationItem } from '@mui/material';
import ViewProductCard from '../../components/views/ViewProductCard';
import DZMenu from '../../components/menu/DZMenu';
import { Loader, NoProductLoader } from '../../components/loaders/Loaders';
import NavSearch from '../../components/navbar/Components/NavSearch/NavSearch';
import { setLocalStorage } from '../../components/localStorage';
import DZText from '../../components/text/DZText';

const ShoPage = () => {
    const { allCategories, allProducts, filterProducts, isShowPagination, currentPage, totalPages, isLoadingProducts, searchQuery,
        searchLoader, productSize, selectedSizeClassName, unSelectedSizeClassName, sortingType, showAllSizes, selectedBtnSizeClassName, unSelectedBtnSizeClassName,
        setSelectedCategoryId, setShowProductsLength, setSortingType, setCurrentPage, setSearchQuery,
        fnOnChange, fnSubmit, fnSelectSize } = useShopPageManager();

    return (
        <>

            <div className="d-flex align-center justify-center w-full">
                <NavSearch fnOnChange={fnOnChange} fnSubmit={fnSubmit} searchQuery={searchQuery} setSearchQuery={setSearchQuery} searchLoader={searchLoader} />
            </div>

            <div className="d-flex align-center justify-center mt-4">

                <div className="d-flex space-between gap-24 w-full max-inner sm-flex-col">

                    <div className="d-flex flex-col gap-20 w-200 sm-w-full">

                        <div className="d-flex flex-col gap-20">

                            <h1 className="fs-24 fw-600 dark-color border-b-1-grey pb-1">
                                <DZText children={'Common.categories'} />
                            </h1>

                            <div className="d-flex flex-col gap-12 sm-flex-row sm-w-full sm-overflow-scroll">

                                <DZMenu
                                    categories={allCategories}
                                    allProducts={allProducts}
                                    setSelectedCategoryId={setSelectedCategoryId}
                                    setCurrentPage={setCurrentPage}
                                />

                            </div>

                        </div>

                        <div className="d-flex flex-wrap gap-12 sm-w-full sm-overflow-scroll xsm-flex-nowrap">

                            <h1 className="fs-24 fw-600 dark-color border-b-1-grey pb-1">
                                <DZText children={'Common.popular_size'}/>
                            </h1>


                            <div className="d-flex flex-wrap gap-12">

                                <div onClick={() => fnSelectSize(PRODUCT_SIZES.ALL)} className={productSize == PRODUCT_SIZES.ALL ? selectedBtnSizeClassName : unSelectedBtnSizeClassName}>

                                    <span className={productSize == PRODUCT_SIZES.ALL ? selectedSizeClassName : unSelectedSizeClassName}>
                                        All
                                    </span>

                                </div>

                                {showAllSizes?.map((size) => {
                                    return (
                                        <div onClick={() => fnSelectSize(size)} className={productSize == size ? selectedBtnSizeClassName : unSelectedBtnSizeClassName} >
                                            <span className={productSize == size ? selectedSizeClassName : unSelectedSizeClassName}>
                                                {size}
                                            </span>
                                        </div>
                                    )
                                })}
                            </div>

                        </div>

                    </div>

                    <div className="d-flex flex-col w-full">

                        <div className="d-flex space-between border-b-1-grey pb-1 sm-flex-col sm-gap-24">

                            <h1 className="fs-24 fw-600 dark-color">
                                <DZText children={'Common.products'} />
                            </h1>

                            {/* <h1 className="fs-24 fw-600 dark-color">
                                <DZText children={'Zia'} />
                            </h1> */}

                            <div className="d-flex align-center gap-12">

                                <select onChange={(e) => { setLocalStorage(KEYS.sort_type, e?.target?.value); setSortingType(e?.target?.value) }} className='border-grey p-4-8 dark-color w-120 outline-none'>
                                    <option selected={sortingType == SORTING_TYPE.DEFAULT} value={SORTING_TYPE.DEFAULT}>Default</option>
                                    <option selected={sortingType == SORTING_TYPE.A_TO_Z} value={SORTING_TYPE.A_TO_Z}>A to Z</option>
                                    <option selected={sortingType == SORTING_TYPE.Z_TO_A} value={SORTING_TYPE.Z_TO_A}>Z to A</option>
                                    <option selected={sortingType == SORTING_TYPE.HIGH_TO_LOW} value={SORTING_TYPE.HIGH_TO_LOW}>High to Low</option>
                                    <option selected={sortingType == SORTING_TYPE.LOW_TO_HIGH} value={SORTING_TYPE.LOW_TO_HIGH}>Low to High</option>
                                </select>

                                <select onChange={(e) => setShowProductsLength(e?.target?.value)} className='border-grey p-4-8 dark-color w-120 outline-none'>
                                    {/* <option value="10">Show 10</option>
                                    <option value="15">Show 15</option> */}
                                    <option selected value="20">Show 20</option>
                                    <option selected value="50">Show 50</option>
                                    <option selected value="100">Show 100</option>
                                </select>

                            </div>

                        </div>

                        {isLoadingProducts ? <Loader svgClassName={'h-80 w-80'} />
                            : <>
                                {filterProducts?.length >= 1 ?
                                    <div className="d-grid grid-colt-auto-226 grid-colt-4 product__cards mt-2">
                                        {filterProducts?.map((product, index) => <ViewProductCard key={index} productSize={productSize} product={product} />)}
                                    </div>
                                    : <NoProductLoader />
                                }
                            </>
                        }

                        {isShowPagination ?
                            <Pagination
                                count={totalPages}
                                page={currentPage}
                                shape='rounded'
                                onChange={(e, p) => setCurrentPage(p)}
                                style={{ marginTop: 20 }}
                                renderItem={(item) => (
                                    <PaginationItem
                                        {...item}
                                        sx={{
                                            color: item.selected ? 'white' : 'black',
                                            '&.Mui-selected': {
                                                backgroundColor: '#00502d',
                                            },
                                            backgroundColor: item.selected ? 'white' : '#EAEFF4',
                                            '&:hover': {
                                                backgroundColor: '#00502d',
                                                color: 'white'// Change background color on hover
                                            },
                                        }}
                                    />
                                )}
                            />
                            : <></>}

                    </div>

                </div>

            </div>

        </>
    )
}

export default ShoPage;

// import React from 'react'
// import { LANGUAGES, SORTING_TYPE } from '../../constants/Index';
// import './ShoPage.css'
// import { useShopPageManager } from '../../lib/customHooks/useShopPageManager';
// import { Pagination, PaginationItem } from '@mui/material';
// import ViewProductCard from '../../components/views/ViewProductCard';
// import { useTranslation } from 'react-i18next';

// import { Dropdown, Space } from "antd";
// import DZText from '../../components/text/DZText';

// const items = [
//     {
//         key: "1",
//         type: "group",
//         children: [
//             {
//                 key: "1-1",
//                 label: "1st menu item",
//             },
//             {
//                 key: "1-2",
//                 label: "2nd menu item",
//             },
//         ],
//     },
//     {
//         key: "2",
//         label: "sub menu",
//         children: [
//             {
//                 key: "2-1",
//                 label: "3rd menu item",
//             },
//             {
//                 key: "2-2",
//                 label: "4th menu item",
//             },
//         ],
//     },
//     {
//         key: "3",
//         label: "sub menu",
//         children: [
//             {
//                 key: "3-1",
//                 label: "5d menu item",
//                 children: [
//                     {
//                         key: "3-1",
//                         label: "5d menu item",
//                         children: [
//                             {
//                                 key: "3-1",
//                                 label: "5d menu item",
//                             },
//                             {
//                                 key: "3-2",
//                                 label: "6th menu item",
//                             },
//                         ],
//                     },
//                     {
//                         key: "3-2",
//                         label: "6th menu item",
//                     },
//                 ],
//             },
//             {
//                 key: "3-2",
//                 label: "6th menu item",
//             },
//         ],
//     },
// ];
// const ShoPage = () => {

//     const { allCategories, allProducts, selectedCategoryId, filterProducts, isShowPagination, currentPage, totalPages,
//         setSelectedCategoryId, setShowProductsLength, setSortingType, setCurrentPage, } = useShopPageManager();

//     return (
//         <>
//             <div className="d-flex align-center justify-center mt-4">

//                 <div className="d-flex space-between gap-24 w-full max-inner sm-flex-col">

//                     <div className="d-flex flex-col gap-20 w-200 sm-w-full">

//                         <div className="d-flex flex-col gap-20">

//                             <h1 className="fs-24 fw-600 dark-color border-b-1-grey pb-1">
//                                 Categories
//                             </h1>

//                             <div className="d-flex flex-col gap-12 sm-flex-row sm-w-full sm-overflow-scroll">

//                                 <span onClick={() => { setSelectedCategoryId(1); setCurrentPage(1) }} className={selectedCategoryId == 1 ? "fs-14 fw-400 green-color text-cap cursor-p" : "fs-14 fw-400 dark-color text-cap cursor-p"}>
//                                     <DZText>{'All Categories'}</DZText>
//                                 </span>

//                                 {allCategories?.map((category, index) => {
//                                     const category_id = category?.id;
//                                     const category_name = category?.name?.en;
//                                     const isProductsAvailable = allProducts?.filter((prod) => prod?.category_id == category?.id)?.length
//                                     return (
//                                         <>
//                                             {isProductsAvailable ? <span key={index} onClick={() => { setSelectedCategoryId(category_id); setCurrentPage(1); }} className={selectedCategoryId == category_id ? "fs-14 fw-400 green-color cursor-p sm-min-w-fit" : "fs-14 fw-400 dark-color cursor-p sm-min-w-fit"}>
//                                                 {category_name}
//                                             </span> : <></>}
//                                         </>
//                                     )
//                                 })}

//                                 <Dropdown menu={{ items }}>
//                                     <a className='fs-14 fw-400 dark-color cursor-p sm-min-w-fit' onClick={(e) => e.preventDefault()}>
//                                         <Space className='fs-14 fw-400 dark-color cursor-p sm-min-w-fit'>Cascading menu</Space>
//                                     </a>
//                                 </Dropdown>

//                             </div>

//                         </div>

//                         <div className="d-flex flex-col gap-20 ">

//                             <h1 className="fs-24 fw-600 dark-color border-b-1-grey pb-1">
//                                 Popular colors
//                             </h1>

//                             <div className="d-flex flex-wrap gap-12 sm-w-full sm-overflow-scroll xsm-flex-nowrap">

//                                 <div className="d-flex align-center gap-12 xsm-w-fit">

//                                     <div className="w-14 h-14 full-radi pink-back"></div>

//                                     <span className="fs-14 fw-400 dark-color cursor-p">
//                                         Pink
//                                     </span>

//                                 </div>

//                                 <div className="d-flex align-center gap-12 xsm-w-fit">

//                                     <div className="w-14 h-14 full-radi green-back"></div>

//                                     <span className="fs-14 fw-400 dark-color cursor-p">
//                                         Green
//                                     </span>

//                                 </div>

//                                 <div className="d-flex align-center gap-12 xsm-w-fit">

//                                     <div className="w-14 h-14 full-radi coffee-back"></div>

//                                     <span className="fs-14 fw-400 dark-color cursor-p">
//                                         Coffee
//                                     </span>

//                                 </div>

//                                 <div className="d-flex align-center gap-12 xsm-w-fit">

//                                     <div className="w-14 h-14 full-radi dark-back"></div>

//                                     <span className="fs-14 fw-400 dark-color cursor-p">
//                                         Dark
//                                     </span>

//                                 </div>

//                                 <div className="d-flex align-center gap-12 xsm-w-fit">

//                                     <div className="w-14 h-14 full-radi green-back"></div>

//                                     <span className="fs-14 fw-400 dark-color cursor-p">
//                                         Green
//                                     </span>

//                                 </div>

//                                 <div className="d-flex align-center gap-12 xsm-w-fit">

//                                     <div className="w-14 h-14 full-radi pink-back"></div>

//                                     <span className="fs-14 fw-400 dark-color cursor-p">
//                                         Pink
//                                     </span>

//                                 </div>

//                                 <div className="d-flex align-center gap-12 xsm-w-fit">

//                                     <div className="w-14 h-14 full-radi dark-back"></div>

//                                     <span className="fs-14 fw-400 dark-color cursor-p">
//                                         Dark
//                                     </span>

//                                 </div>

//                                 <div className="d-flex align-center gap-12 xsm-w-fit">

//                                     <div className="w-14 h-14 full-radi coffee-back"></div>

//                                     <span className="fs-14 fw-400 dark-color cursor-p">
//                                         Coffee
//                                     </span>

//                                 </div>

//                             </div>

//                         </div>

//                         <div className="d-flex flex-col gap-20 w-full ">

//                             <h1 className="fs-24 fw-600 dark-color border-b-1-grey pb-1">
//                                 Popular size
//                             </h1>

//                             <div className="d-flex flex-wrap gap-12">

//                                 <div className="d-flex align-center w-fit">

//                                     <span className="fs-14 fw-400 green-color cursor-p">
//                                         All Size
//                                     </span>

//                                 </div>

//                                 <div className="d-flex align-center w-fit">

//                                     <span className="fs-14 fw-400 dark-color cursor-p">
//                                         M
//                                     </span>

//                                 </div>

//                                 <div className="d-flex align-center w-fit">

//                                     <span className="fs-14 fw-400 dark-color cursor-p">
//                                         S
//                                     </span>

//                                 </div>

//                                 <div className="d-flex align-center w-fit">

//                                     <span className="fs-14 fw-400 dark-color cursor-p">
//                                         XL
//                                     </span>

//                                 </div>

//                                 <div className="d-flex align-center w-fit">

//                                     <span className="fs-14 fw-400 dark-color cursor-p">
//                                         L
//                                     </span>

//                                 </div>

//                             </div>

//                         </div>

//                         <div className="d-flex flex-col gap-20 w-full ">

//                             <h1 className="fs-24 fw-600 dark-color border-b-1-grey pb-1">
//                                 Popular tags
//                             </h1>

//                             <div className="d-flex flex-wrap gap-12 sm-w-full sm-overflow-scroll xsm-flex-nowrap">

//                                 <div className="d-flex align-center w-fit light-back text-center p-4-8 cursor-p sm-min-w-fit">

//                                     <span className="fs-14 fw-400 dark-color">
//                                         All Tag
//                                     </span>

//                                 </div>

//                                 <div className="d-flex align-center w-fit light-back text-center p-4-8 cursor-p sm-min-w-fit">

//                                     <span className="fs-14 fw-400 dark-color">
//                                         Fresh
//                                     </span>

//                                 </div>

//                                 <div className="d-flex align-center w-fit light-back text-center p-4-8 cursor-p sm-min-w-fit">

//                                     <span className="fs-14 fw-400 dark-color">
//                                         Vegetable
//                                     </span>

//                                 </div>

//                                 <div className="d-flex align-center w-fit light-back text-center p-4-8 cursor-p sm-min-w-fit">

//                                     <span className="fs-14 fw-400 dark-color">
//                                         All Tag
//                                     </span>

//                                 </div>

//                                 <div className="d-flex align-center w-fit light-back text-center p-4-8 cursor-p sm-min-w-fit">

//                                     <span className="fs-14 fw-400 dark-color">
//                                         Fresh
//                                     </span>

//                                 </div>

//                                 <div className="d-flex align-center w-fit light-back text-center p-4-8 cursor-p sm-min-w-fit">

//                                     <span className="fs-14 fw-400 dark-color">
//                                         Vegetable
//                                     </span>

//                                 </div>

//                                 <div className="d-flex align-center w-fit p-4-8 green-back text-center cursor-p sm-min-w-fit">

//                                     <span className="fs-14 fw-400 white-color ">
//                                         Meat
//                                     </span>

//                                 </div>

//                             </div>

//                         </div>

//                     </div>

//                     <div className="d-flex flex-col w-full">

//                         <div className="d-flex space-between border-b-1-grey pb-1 sm-flex-col sm-gap-24">

//                             <h1 className="fs-24 fw-600 dark-color">
//                                 Shop Grid Fullwidth
//                             </h1>

//                             <div className="d-flex align-center gap-12">

//                                 <select onChange={(e) => setSortingType(e?.target?.value)} className='border-grey p-4-8 dark-color w-120 outline-none'>
//                                     <option value={SORTING_TYPE.DEFAULT}>Default</option>
//                                     <option value={SORTING_TYPE.A_TO_Z}>A to Z</option>
//                                     <option value={SORTING_TYPE.Z_TO_A}>Z to A</option>
//                                     <option value={SORTING_TYPE.HIGH_TO_LOW}>High to Low</option>
//                                     <option value={SORTING_TYPE.LOW_TO_HIGH}>Low to High</option>
//                                 </select>

//                                 <select onChange={(e) => setShowProductsLength(e?.target?.value)} className='border-grey p-4-8 dark-color w-120 outline-none'>
//                                     <option value="10">Show 10</option>
//                                     <option value="15">Show 15</option>
//                                     <option value="20">Show 20</option>
//                                 </select>

//                             </div>

//                         </div>

//                         <div className="d-grid grid-colt-auto-226 product__cards mt-2">
//                             {filterProducts?.map((product, index) => <ViewProductCard key={index} product={product} />)}
//                         </div>

//                         {isShowPagination ?
//                             // <Pagination
//                             //     sx={{ color: 'red', backgroundColor: 'red' }}
//                             //     count={totalPages}
//                             //     // color='primary'
//                             //     page={currentPage}
//                             //     shape='rounded'
//                             //     onChange={(e, p) => setCurrentPage(p)}
//                             //     style={{ marginTop: 20, colorRendering: 'red' }}
//                             // />
//                             <Pagination
//                                 count={totalPages}
//                                 page={currentPage}
//                                 shape='rounded'
//                                 onChange={(e, p) => setCurrentPage(p)}
//                                 style={{ marginTop: 20 }}
//                                 renderItem={(item) => (
//                                     <PaginationItem
//                                         {...item}
//                                         sx={{
//                                             color: item.selected ? 'white' : 'black', // Change color for the selected page
//                                             '&.Mui-selected': {
//                                                 backgroundColor: '#00502d', // Set background color for the selected page
//                                             },
//                                             backgroundColor: item.selected ? 'white' : '#EAEFF4', // Change background color for the selected and unselected pages
//                                             '&:hover': {
//                                                 backgroundColor: '#00502d',
//                                                 color: 'white'// Change background color on hover
//                                             },
//                                         }}
//                                     />
//                                 )}
//                             />
//                             : <></>}

//                     </div>

//                 </div>

//             </div>
//         </>
//     )
// }

// export default ShoPage;

// import React, { useState } from 'react'
// import { useGetCategoriesQuery, useGetProductsQuery } from '../../redux/storeApis'
// import { Config } from '../../constants/Index';
// import './ShoPage.css'

// const ShoPage = () => {
//     const { data: allCategories } = useGetCategoriesQuery();
//     const { data: allProducts, refetch: refetchAllProducts } = useGetProductsQuery();

//     const [selectedCategoryId, setSelectedCategoryId] = useState(1);
//     const [showProductsLength, setShowProductsLength] = useState(10);

//     const showAllMinProducts = allProducts?.slice(0, showProductsLength);
//     const showAllMinProductsWithCategory = allProducts?.filter((prod) => prod?.category_id == selectedCategoryId)?.slice(0, showProductsLength);
//     const filterProducts = selectedCategoryId == 1 ? showAllMinProducts : showAllMinProductsWithCategory;


//     return (
//         <div className="d-flex align-center justify-center mt-4">

//             <div className="d-flex space-between gap-24 w-full max-inner">

//                 <div className="d-flex flex-col gap-20 w-200">

//                     <div className="d-flex flex-col gap-20">

//                         <h1 className="fs-24 fw-600 dark-color border-b-1-grey pb-1">
//                             Departments
//                         </h1>

//                         <div className="d-flex flex-col gap-12">

//                             <span onClick={() => setSelectedCategoryId(1)} className={selectedCategoryId == 1 ? "fs-14 fw-400 green-color" : "fs-14 fw-400 dark-color"}>
//                                 All Products
//                             </span>

//                             {allCategories?.map((category, index) => {
//                                 const category_id = category?.id;
//                                 const category_name = category?.name?.en;
//                                 const isProductsAvailable = allProducts?.filter((prod) => prod?.category_id == category?.id)?.length
//                                 return (
//                                     <>
//                                         {isProductsAvailable ? <span key={index} onClick={() => setSelectedCategoryId(category_id)} className={selectedCategoryId == category_id ? "fs-14 fw-400 green-color" : "fs-14 fw-400 dark-color"}>
//                                             {category_name}
//                                         </span> : <></>}
//                                     </>
//                                 )
//                             })}

//                         </div>

//                     </div>

//                     <div className="d-flex flex-col gap-20">

//                         <h1 className="fs-24 fw-600 dark-color border-b-1-grey pb-1">
//                             Popular colors
//                         </h1>

//                         <div className="d-flex flex-wrap gap-12">

//                             <div className="d-flex align-center gap-12">

//                                 <div className="w-14 h-14 full-radi pink-back"></div>

//                                 <span className="fs-14 fw-400 dark-color">
//                                     Pink
//                                 </span>

//                             </div>

//                             <div className="d-flex align-center gap-12">

//                                 <div className="w-14 h-14 full-radi green-back"></div>

//                                 <span className="fs-14 fw-400 dark-color">
//                                     Green
//                                 </span>

//                             </div>

//                             <div className="d-flex align-center gap-12">

//                                 <div className="w-14 h-14 full-radi coffee-back"></div>

//                                 <span className="fs-14 fw-400 dark-color">
//                                     Coffee
//                                 </span>

//                             </div>

//                             <div className="d-flex align-center gap-12">

//                                 <div className="w-14 h-14 full-radi dark-back"></div>

//                                 <span className="fs-14 fw-400 dark-color">
//                                     Dark
//                                 </span>

//                             </div>

//                             <div className="d-flex align-center gap-12">

//                                 <div className="w-14 h-14 full-radi green-back"></div>

//                                 <span className="fs-14 fw-400 dark-color">
//                                     Green
//                                 </span>

//                             </div>

//                             <div className="d-flex align-center gap-12">

//                                 <div className="w-14 h-14 full-radi pink-back"></div>

//                                 <span className="fs-14 fw-400 dark-color">
//                                     Pink
//                                 </span>

//                             </div>

//                             <div className="d-flex align-center gap-12">

//                                 <div className="w-14 h-14 full-radi dark-back"></div>

//                                 <span className="fs-14 fw-400 dark-color">
//                                     Dark
//                                 </span>

//                             </div>

//                             <div className="d-flex align-center gap-12">

//                                 <div className="w-14 h-14 full-radi coffee-back"></div>

//                                 <span className="fs-14 fw-400 dark-color">
//                                     Coffee
//                                 </span>

//                             </div>

//                         </div>

//                     </div>

//                     <div className="d-flex flex-col gap-20 w-full">

//                         <h1 className="fs-24 fw-600 dark-color border-b-1-grey pb-1">
//                             Popular size
//                         </h1>

//                         <div className="d-flex flex-wrap gap-12">

//                             <div className="d-flex align-center w-fit">

//                                 <span className="fs-14 fw-400 green-color">
//                                     All Size
//                                 </span>

//                             </div>

//                             <div className="d-flex align-center w-fit">

//                                 <span className="fs-14 fw-400 dark-color">
//                                     M
//                                 </span>

//                             </div>

//                             <div className="d-flex align-center w-fit">

//                                 <span className="fs-14 fw-400 dark-color">
//                                     S
//                                 </span>

//                             </div>

//                             <div className="d-flex align-center w-fit">

//                                 <span className="fs-14 fw-400 dark-color">
//                                     XL
//                                 </span>

//                             </div>

//                             <div className="d-flex align-center w-fit">

//                                 <span className="fs-14 fw-400 dark-color">
//                                     L
//                                 </span>

//                             </div>

//                         </div>

//                     </div>

//                     <div className="d-flex flex-col gap-20 w-full">

//                         <h1 className="fs-24 fw-600 dark-color border-b-1-grey pb-1">
//                             Popular tags
//                         </h1>

//                         <div className="d-flex flex-wrap gap-12">

//                             <div className="d-flex align-center w-fit light-back text-center p-4-8 cursor-p">

//                                 <span className="fs-14 fw-400 dark-color">
//                                     All Tag
//                                 </span>

//                             </div>

//                             <div className="d-flex align-center w-fit light-back text-center p-4-8 cursor-p">

//                                 <span className="fs-14 fw-400 dark-color">
//                                     Fresh
//                                 </span>

//                             </div>

//                             <div className="d-flex align-center w-fit light-back text-center p-4-8 cursor-p">

//                                 <span className="fs-14 fw-400 dark-color">
//                                     Vegetable
//                                 </span>

//                             </div>

//                             <div className="d-flex align-center w-fit light-back text-center p-4-8 cursor-p">

//                                 <span className="fs-14 fw-400 dark-color">
//                                     All Tag
//                                 </span>

//                             </div>

//                             <div className="d-flex align-center w-fit light-back text-center p-4-8 cursor-p">

//                                 <span className="fs-14 fw-400 dark-color">
//                                     Fresh
//                                 </span>

//                             </div>

//                             <div className="d-flex align-center w-fit light-back text-center p-4-8 cursor-p">

//                                 <span className="fs-14 fw-400 dark-color">
//                                     Vegetable
//                                 </span>

//                             </div>

//                             <div className="d-flex align-center w-fit p-4-8 green-back text-center cursor-p">

//                                 <span className="fs-14 fw-400 white-color ">
//                                     Meat
//                                 </span>

//                             </div>

//                         </div>

//                     </div>

//                 </div>

//                 <div className="d-flex flex-col w-full">

//                     <div className="d-flex space-between border-b-1-grey pb-1">

//                         <h1 className="fs-24 fw-600 dark-color">
//                             Shop Grid Fullwidth
//                         </h1>

//                         <div className="d-flex align-center gap-12">

//                             <select className='border-grey p-4-8 dark-color w-120 outline-none'>
//                                 <option value="">Default</option>
//                                 <option value="">A to Z</option>
//                                 <option value="">Z to A</option>
//                                 <option value="">High to Low</option>
//                                 <option value="">Low to High</option>
//                             </select>

//                             <select onChange={(e) => setShowProductsLength(e?.target?.value)} className='border-grey p-4-8 dark-color w-120 outline-none'>
//                                 <option value="10">Show 10</option>
//                                 <option value="15">Show 15</option>
//                                 <option value="20">Show 20</option>
//                             </select>

//                         </div>

//                     </div>

//                     <div className="d-grid grid-colt-auto-300 product__cards mt-2">

//                         {filterProducts?.map((prod, index) => {

//                             const prodName = prod?.title?.en;
//                             const prodPrice = `$${prod?.price}`;
//                             const imgUrl = Config.getProductImage(prod?.image);
//                             const categoryName = allCategories?.find((category) => category?.id == prod?.category_id)?.name?.en;

//                             return (
//                                 <div key={index} className="d-flex flex-col align-center justify-center gap-2 product__card p-12 w-full">

//                                     <div className="img">
//                                         <img src={imgUrl} className='w-200 mb-1' alt="" />
//                                     </div>
//                                     <span className="fw-600 fs-16 green-color mt-1"> {categoryName} </span>
//                                     <span className="fw-400 fs-24 dark-color product__name"> {prodName} </span>
//                                     <span className="fw-600 fs-18 dark-color price__span">
//                                         {prodPrice} <span className='fs-12' style={{ textDecoration: 'line-through', color: '#999' }}>
//                                             $35
//                                         </span>
//                                     </span>

//                                     <div className="d-flex align-center justify-center gap-12 icon__container">
//                                         <i className="fa-regular fa-heart dark-color fs-14 light-back p-8 full-radi cursor-p hover-green-back transition-03 hover-white"></i>
//                                         <i className="fa-solid fa-cart-shopping dark-color fs-14 light-back p-8 full-radi cursor-p hover-green-back transition-03 hover-white"></i>
//                                         <i className="fa-solid fa-eye dark-color fs-14 light-back p-8 full-radi cursor-p hover-green-back transition-03 hover-white"></i>
//                                     </div>

//                                 </div>
//                             )
//                         })}

//                     </div>

//                     <div className="d-flex align-center gap-12 mt-4">

//                         <div className={"d-flex align-center justify-center fs-20 dark-color light-back w-32 h-32 cursor-p hover-white hover-green-back transition-03"}>
//                             {"<"}
//                         </div>

//                         <div className="d-flex align-center justify-center fs-14 green-back white-color fw-600 w-32 h-32 cursor-p ">
//                             1
//                         </div>

//                         <div className="d-flex align-center justify-center fs-14 fw-500 dark-color light-back w-32 h-32 cursor-p hover-white hover-green-back transition-03">
//                             2
//                         </div>

//                         <div className="d-flex align-center justify-center fs-14 fw-500 dark-color light-back w-32 h-32 cursor-p hover-white hover-green-back transition-03">
//                             3
//                         </div>

//                         <div className="d-flex align-center justify-center fs-14 fw-500 dark-color light-back w-32 h-32 cursor-p hover-white hover-green-back transition-03">
//                             4
//                         </div>

//                         <div className="d-flex align-center justify-center fs-14 fw-500 dark-color light-back w-32 h-32 cursor-p hover-white hover-green-back transition-03">
//                             5
//                         </div>

//                         <div className="d-flex align-center justify-center fs-14 fw-500 dark-color light-back w-32 h-32 cursor-p hover-white hover-green-back transition-03">
//                             6
//                         </div>

//                         <div className="d-flex align-center justify-center fs-20 dark-color light-back w-32 h-32 cursor-p hover-white hover-green-back transition-03">
//                             {">"}
//                         </div>

//                     </div>

//                 </div>

//             </div>

//         </div >
//     )
// }

// export default ShoPage