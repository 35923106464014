import React, { useEffect, useState } from 'react'
import { useUserManager } from '../../lib/customHooks/useUserManager';
import { useAddQueryMutation } from '../../redux/storeApis';
import { useSnackBarManager } from '../../lib/customHooks/useSnackBarManager';
import { BtnLoader } from '../../components/loaders/Loaders';
import DZText from '../../components/text/DZText';
import { useTranslation } from 'react-i18next';
import { useLinksManager } from '../../lib/customHooks/useLinksManager';
import GoogleMapMark from '../../components/google-map/GoogleMapMark';

const ContactPage = () => {

  const { t } = useTranslation();
  const [sendQuery] = useAddQueryMutation();
  const { userName, userEmail, isLoggedInUser, isSuccessUser } = useUserManager();
  const { fnShowSnackBar } = useSnackBarManager();
  const { restaurant_lat, restaurant_long, restaurant_address, restaurant_email } = useLinksManager();

  const markers = [
    {
      id: 1,
      name: "Restaurant",
      position: {
        lat: Number(restaurant_lat),
        lng: Number(restaurant_long)
      },
    },
  ];

  const [userData, setUserData] = useState({ name: "", email: "", message: "", });
  const [loader, setLoader] = useState(false);

  const { name, email, message } = userData;

  useEffect(() => { if (isLoggedInUser) { setUserData({ ...userData, name: userName, email: userEmail, }); } }, [isSuccessUser, isLoggedInUser]);

  const fnInputChange = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
  };

  const fnAddMessage = () => {
    if (message) {
      setLoader(true)
      sendQuery({ name, email, message }).unwrap().then((res) => {
        setLoader(false);
        if (isLoggedInUser) { setUserData({ ...userData, message: '' }) }
        else { setUserData({ ...userData, name: "", email: "", message: '' }) }
        fnShowSnackBar('Send message successfully!');
      }).catch((err) => { setLoader(false); fnShowSnackBar('Some error occurred!'); });
    } else if (!message) {
      fnShowSnackBar('Please write some message')
    }
  }

  const fnSendMessage = () => {
    if (isLoggedInUser) { fnAddMessage(); }
    else {
      if (!name && !email && !message) { fnShowSnackBar('Fill all the fields!'); }
      else { fnAddMessage() }
    }
  };

  return (
    <div className="d-flex align-center justify-center flex-col mt-4 mb-4">

      <div className="max-inner w-full">
        {markers && <GoogleMapMark markers={markers} />}
        {/* <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4365.332623021646!2d10.419401305829068!3d55.40458186760181!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x464d1ff526a0e069%3A0x2b09e43cf7b993cf!2sBauhaus!5e0!3m2!1sen!2s!4v1706536402885!5m2!1sen!2s"
          height="500"
          style={{ border: '0' }}
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
          className='w-full'
        ></iframe> */}

      </div>

      <div className="d-flex align-center space-between gap-24 mt-4 max-inner w-full xxsm-flex-col">

        <div className="d-flex flex-col align-center gap-12 w-full">

          <i className="fa-solid fa-location-dot fs-48 mb-3 green-color"></i>

          <a href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(restaurant_address)}`} target="_blank" rel="noopener noreferrer" >
            <span className="fs-16 fw-500 dark-color text-center hover-green transition-03">
              {restaurant_address}
            </span>
          </a>

        </div>

        <div className="d-flex flex-col align-center gap-12 w-full">

          <i className="fa-regular fa-envelope  fs-48 mb-3 green-color"></i>

          <a href={`mailto:${restaurant_email}`}>
            <span className="fs-16 fw-500 dark-color text-center hover-green transition-03">
              {restaurant_email}
            </span>
          </a>

        </div>

        {/* <div className="d-flex flex-col align-center gap-12 w-full">

          <i className="fa-regular fa-clock fs-48 mb-3 green-color"></i>

          <span className="fs-16 fw-500 dark-color text-center">
            Week Days: 10:00 – 22:00
          </span>

          <span className="fs-16 fw-500 dark-color">
            Sunday: Close
          </span>

        </div> */}

      </div>

      <div className="d-flex flex-col align-center gap-12 mt-4 w-full max-inner">

        <h2 className="fs-36 fw-500 dark-color text-center">
          <DZText children={'Contact.give_feedback'} />
        </h2>

        <span className="fs-16 fw-400 dark-color opacity-06 text-center">
          <DZText children={'Contact.our_staff_will_call_back_later_and_answer_your_questions'} />
        </span>

        <div className="w-full d-grid gap-24 grid-colt-2 mt-2 xxsm-d-flex xxsm-flex-col">

          <input type="text" placeholder={t('Common.name')} value={name} name='name' onChange={fnInputChange} className='p-8-10 outline-none border-grey dark-color w-full' />

          <input type="text" placeholder={t('Common.email')} value={email} name='email' onChange={fnInputChange} className='p-8-10 outline-none border-grey dark-color w-full' />

          <textarea type="text" placeholder={t('Common.message')} name='message' value={message} onChange={fnInputChange} className='grid-col-1-3 p-8-10 outline-none border-grey dark-color w-full resize-v min-h-200' />

        </div>

        <div className="w-full">

          <button onClick={() => fnSendMessage()} className="p-10-16 green-back border-0 outline-none white-color fs-16 fw-500 cursor-p w-180 h-48">
            {loader ? <BtnLoader /> : <DZText children={'Common.send_message'} />}
          </button>

        </div>

      </div>

    </div>
  )
}

export default ContactPage;