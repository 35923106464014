import React from 'react'

const SingleBlogPage = () => {
    return (
        <div className='d-flex flex-col gap-24'>

            <h2 className="fs-32 fw-600 dark-color sm-fs-28">
                Potatoes 101: All You Need to Know About Common Spuds
            </h2>

            <img src="https://ogami-react.vercel.app/assets/images/blog/blog-detail/1.png" alt="" className='max-w-650' />

            <p className="fs-16 dark-color sm-fs-14">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in.
            </p>

            <h2 className="fs-24 fw-600 dark-color sm-fs-18">
                You Can Buy For Less Than A College Degree
            </h2>

            <img src="https://ogami-react.vercel.app/assets/images/blog/blog-detail/2.png" alt="" className='max-w-650' />

            <p className="fs-16 dark-color sm-fs-14">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in.
            </p>

            <p className="fs-16 dark-color sm-fs-14">
                Aboris nisi ut aliquip ex ea commodo consequat. Duis aute irure Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in

                Enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo conslaboris nisi ut aliquip ex ea commodo consequat. Duis aute irure Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim ve niam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in datat non proident, sunt in. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus error sit volupt atem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis dicta sunt explicabo.
            </p>

            <div className="d-flex align-center space-between sm-gap-24 sm-flex-col sm-align-start">

                <div className="d-flex flex-wrap gap-16">

                    <span className="fs-14 white-color p-4-8 green-back w-fit">
                        All Tags
                    </span>

                    <span className="fs-14 dark-color p-4-8 light-back w-fit">
                        Fresh
                    </span>

                    <span className="fs-14 dark-color p-4-8 light-back w-fit">
                        Vegitables
                    </span>

                    <span className="fs-14 dark-color p-4-8 light-back w-fit">
                        Meat
                    </span>

                </div>

                <div className="d-flex align-center gap-12">

                    <span className="fs-16 fw-600 dark-color">
                        Share:
                    </span>

                    <i className="dark-color transition-03 hover-green cursor-p fa-brands fa-facebook-f"></i>

                    <i className="dark-color transition-03 hover-green cursor-p fa-brands fa-instagram"></i>

                    <i className="dark-color transition-03 hover-green cursor-p fa-brands fa-x-twitter"></i>

                    <i className="dark-color transition-03 hover-green cursor-p fa-brands fa-pinterest-p"></i>

                </div>

            </div>

        </div>
    )
}

export default SingleBlogPage;