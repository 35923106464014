import { Menu } from 'antd';
import React from 'react';
import './DZMenu.css'
import DZText from '../text/DZText';
import { Loader } from '../loaders/Loaders';

const DZMenu = ({ categories, setSelectedCategoryId, setCurrentPage, allProducts }) => {

  const convertToMenuItems = (categories) => {
    return (categories?.map(category => {
      const category_id = category?.id;
      const menuItem = {
        label: <DZText children={category?.name} />,
        key: `opt${category_id}`,
        onClick: () => {
          if (!category?.subcategories || category?.subcategories?.length === 0) {
            setSelectedCategoryId(category?.id);
            setCurrentPage(1);
          }
        }
      };

      if (category?.subcategories && category?.subcategories?.length > 0) {
        menuItem.children = convertToMenuItems(category?.subcategories);
      };

      return menuItem;
    })) ?? []
  };
  const allCategoriesItem = { label: <DZText children={'Common.all_categories'} />, key: 'all', onClick: () => { setSelectedCategoryId(1); setCurrentPage(1) } };
  const items = [allCategoriesItem, ...convertToMenuItems(categories)];

  return (
    <>
      {
        categories ? <Menu items={items} defaultSelectedKeys={['all']} style={{ borderWidth: 0 }} /> : <Loader svgClassName={'w-40 h-40'} />
      }
    </>
  );
};

export default DZMenu;