import React, { useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { ROUTES } from "../../../../reactRoute/RouteConstants";
import useCartManager from "../../../../lib/customHooks/useCartManager";
import MobileNavBar from "../MobileNavBar/MobileNavBar";
import { useSelector } from "react-redux";
import { selectedCartSelector } from "../../../../redux/CartReducer";
import DZText from "../../../text/DZText";
import { useUserManager } from "../../../../lib/customHooks/useUserManager";

const LinkNavBar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { fnShowCartTotalPrice } = useCartManager();
  const { isLoggedInUser } = useUserManager();
  const cartItemsList = useSelector(selectedCartSelector);
  const totalProductsInCart = cartItemsList?.length ?? 0;

  const [mobileNavBarClass, setMobileNavBarClass] = useState("d-none");

  const fnIsActivePath = (path) => {
    if (location.pathname === path) {
      return "dark-color fw-500 fs-16 hover-green cursor-p transition-03 green-color";
    } else {
      return "dark-color fw-500 fs-16 hover-green cursor-p transition-03";
    }
  };

  return (
    <>
      {mobileNavBarClass == "d-flex" ? (
        <MobileNavBar setMobileNavBarClass={setMobileNavBarClass} />
      ) : (
        <></>
      )}
      <div className="d-flex align-center space-between max-inner w-full p-20-0">
        <i
          onClick={() => setMobileNavBarClass("d-flex")}
          className="fa-solid fa-bars dark-color fs-24 d-none-xsm cursor-p"
        ></i>

        <img
          onClick={() => navigate(ROUTES.home)}
          src="images/logo.jpg"
          alt=""
          className="w-100 cursor-p"
        />

        <div className="d-flex align-center gap-32 xsm-d-none">
          <NavLink to={ROUTES.home} className={fnIsActivePath(ROUTES.home)}>
            <DZText children={"Link_Tabs.home"} />
          </NavLink>

          <NavLink to={ROUTES.shop} className={fnIsActivePath(ROUTES.shop)}>
            <DZText children={"Link_Tabs.shop"} />
          </NavLink>

          <NavLink to={ROUTES.services} className={fnIsActivePath(ROUTES.services)}>
            <DZText children={"Link_Tabs.services"} />
          </NavLink>

          <NavLink to={ROUTES.contact} className={fnIsActivePath(ROUTES.contact)} >
            <DZText children={"Link_Tabs.contact_us"} />
          </NavLink>

          <NavLink to={ROUTES.about} className={fnIsActivePath(ROUTES.about)}>
            <DZText children={"Link_Tabs.about_us"} />
          </NavLink>

          {/* <NavLink to={ROUTES.blog} className={fnIsActivePath(ROUTES.blog)}>
            <DZText children={"Link_Tabs.blogs"} />
          </NavLink> */}

          {isLoggedInUser && <NavLink to={ROUTES.profile} className={fnIsActivePath(ROUTES.profile)}>
            <DZText children={"Link_Tabs.profile"} />
          </NavLink>}
        </div>

        <div className="d-flex align-center gap-16">
          {/* <i
            className="fa-regular fa-heart hover-green cursor-p transition-03 fs-20 dark-color"
            onClick={() => navigate(ROUTES.wishlist)}
          ></i> */}

          <div
            className="d-flex align-center gap-12 hover-green cursor-p transition-03 position-rel"
            onClick={() => navigate(ROUTES.cart)}
          >
            <span className="fs-10 green-back w-20 h-20 radi-40 d-flex align-center justify-center white-color position-abs l-10 b-10">
              {totalProductsInCart}
            </span>

            <i className="fa-solid fa-bag-shopping fs-20 dark-color hover-green cursor-p transition-03"></i>

            <span className="fw-500 color-dark fs-14">
              {fnShowCartTotalPrice()}
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default LinkNavBar;
