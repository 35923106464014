import { DateTime } from 'luxon';

export const MONTHS_FULL = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
export const MONTHS_SHORT = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec",];


export function convertUtcToLocal(utcDateStr) {
    if (!utcDateStr) {
        return null; // or return a default value or handle the error as needed
    }

    const format = 'yyyy-MM-dd HH:mm:ss';
    const utcDate = DateTime.fromFormat(utcDateStr, format, { zone: 'utc' });
    const localDate = utcDate.toLocal();
    return new Date(localDate);
}

export const getWeekDays = (days) => {
    const strDay = parseInt(days % 7);
    const strWeek = parseInt(days / 7);
    return (strWeek > 0 ? strWeek + ' Week(s) ' : '') + (strDay > 0 ? strDay + ' Day(s)' : '')
};

export const getDate_dd_fullMonth = (dt, char = '-') => {
    dt = new Date()
    if (dt) {
        const day = dt.getDate()
        return day + (day % 10 == 1 ? 'st' : day % 10 == 2 ? 'nd' : day % 10 == 3 ? 'rd' : 'th') + char + MONTHS_FULL[dt.getMonth()];
    }
    return dt;
}

export const getDate_dd_fullMonth_yyyy = (dt, char = '-') => {
    dt = new Date()
    if (dt) return dt.getDate() + char + MONTHS_FULL[dt.getMonth()] + char + dt.getFullYear();
    return dt;
}

export const getDate_dd_mmm_yy = (dt, char = '.') => {
    dt = new Date()
    if (dt) return dt.getDate() + char + ' ' + MONTHS_SHORT[dt.getMonth()] + char + dt.getFullYear().toString().substring(2);
    return dt;
}

export const getDate_yyyy_mm_dd = (dt, char = '-') => {
    dt = new Date();
    if (dt) return dt?.getFullYear() + char + (dt?.getMonth() + 1)?.toString()?.padStart(2, '0') + char + dt?.getDate()?.toString()?.padStart(2, '0');
    return dt;
}

export const getDate_yyyy_mm_dd_hh_mm_ss = (dt, char = '-') => {
    dt = new Date()
    if (dt) return getDate_yyyy_mm_dd(dt) + " " + dt.getHours().toString().padStart(2, '0') + ":" + dt.getMinutes().toString().padStart(2, '0') + ":" + dt.getSeconds().toString().padStart(2, '0');
    return dt;
}

export const getTime_hh_mm = (dt, char = ':') => {
    dt = new Date()
    if (dt) return dt.getHours().toString().padStart(2, '0') + char + dt.getMinutes().toString().padStart(2, '0');
    return dt;
}

/**
     * minus dt2 from dt1 dt2-dt1;
     * @param { dt1 } dt1 = null default=null
     * @param { dt2 } dt2 = null default=nulls
     * @returns 
*/
export const get_date_diff = (dt1, dt2) => {
    if (typeof dt1 === 'string') dt1 = new Date(dt1);
    if (typeof dt2 === 'string') dt2 = new Date(dt2);
    dt1.setHours(0); dt1.setMinutes(0); dt1.setSeconds(0);
    dt2.setHours(0); dt2.setMinutes(0); dt2.setSeconds(0);
    var diffOriginal = dt2 - dt1;
    var diffInDays = diffOriginal / (1000 * 60 * 60 * 24);
    // if(diffInDays<0) return diffInDays;
    return diffInDays
};

export const date_diff_In_days = (dt1, dt2) => {
    if (typeof dt1 === 'string') dt1 = new Date(dt1);
    if (typeof dt2 === 'string') dt2 = new Date(dt2);
    return Math.floor((Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) - Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) / (1000 * 60 * 60 * 24));
};

// export const get_date_diff_in_minutes = (dt1, dt2) => {
//     if(typeof dt1 === 'string') dt1= new Date(dt1);
//     if(typeof dt2 === 'string') dt2= new Date(dt2);
//       const diffInMilliseconds = Math.abs(dt2 - dt1);
//       const diffInMinutes = (diffInMilliseconds / 60000);

//     return diffInMinutes
// };

export const get_date_diff_in_minutes = (dt1, dt2) => {
    if (typeof dt1 === 'string') dt1 = new Date(dt1);
    if (typeof dt2 === 'string') dt2 = new Date(dt2);

    // Set seconds and milliseconds to 0 for both dates
    dt1?.setSeconds(0, 0);
    dt2?.setSeconds(0, 0);

    const diffInMilliseconds = Math.abs(dt2 - dt1);
    const diffInMinutes = diffInMilliseconds / (60 * 1000);

    return diffInMinutes;
};

export const getNumberOfWeekdays = (start, end) => {
    // Create date objects for the start and end dates
    // var start = new Date(startDate);
    // var end = new Date(endDate);

    if (typeof end === 'string') end = new Date(end?.substring(0, 10));

    // Initialize the count of non-weekend days to 0
    var weekdays = 0;

    // Iterate through each day in the period
    for (var date = start; date <= end; date.setDate(date.getDate() + 1)) {
        // If the day is not a weekend day, increment the count
        if (date.getDay() > 0 && date.getDay() < 6) {
            weekdays++;
        }
    }

    // Return the count of non-weekend days
    return weekdays;
}

export const getWeekends = (start, end) => {
    // Create date objects for the start and end dates
    // var start = new Date(startDate);
    // var end = new Date(endDate);

    // Initialize the count of non-weekend days to 0
    var weekends = 0;

    // Iterate through each day in the period
    for (var date = start; date <= end; date.setDate(date.getDate() + 1)) {
        // If the day is not a weekend day, increment the count
        if (date.getDay() == 0 || date.getDay() == 6) {
            weekends++;
        }
    }

    // Return the count of non-weekend days
    return weekends;
}
