import React, { useEffect, useState } from 'react'
import './Addressess.css'
import { useDeleteAddressMutation, useUserAddressesQuery } from '../../redux/storeApis'
import { Loader, NoAddressLoader } from '../loaders/Loaders';
import { useSnackBarManager } from '../../lib/customHooks/useSnackBarManager';
import { Box, Button, Modal } from '@mui/material';
import Popup from '../popup/Popup';
import PermissionPopup from '../popups/PermissionPopup';
import DawaAutoAddress from '../dawa-address/DawaAutoAddress';


const Addresses = () => {

    const { data: userAddresses, refetch: refetchAddresses, isLoading: isLoadingAddresses } = useUserAddressesQuery();
    const userAddressList = userAddresses?.data;
    const isUserAddressesExist = userAddressList?.length >= 1;

    const [deleteAddress] = useDeleteAddressMutation();
    const { fnShowSnackBar } = useSnackBarManager()
    const [deleteModal, setDeleteModal] = useState({ status: false, id: null });
    const [addressPopup, setAddressPopup] = useState(false);

    useEffect(() => { refetchAddresses(); }, []);

    const fnDeleteAddress = () => {
        deleteAddress(deleteModal?.id).unwrap().then((payload) => {
            if (payload.success) {
                setTimeout(() => {
                    setDeleteModal({ id: null, status: false });
                    fnShowSnackBar('Address Deleted Successfully');
                }, 1000)

            }
        }).catch((error) => {
            setDeleteModal({ id: null, status: false });
            fnShowSnackBar('Address Deleted Successfully');
        })
    };

    return (
        <div className="d-flex flex-col gap-24 align-center w-full">

            <div className='d-flex flex-row w-full space-between'>
                <span className="fs-20 fw-600 dark-color text-center">Addresses</span>
                <button style={styles.btn} onClick={() => setAddressPopup(true)} >{'Add'}</button>
            </div>

            {isLoadingAddresses ? <Loader svgClassName={"w-60 h-60"} loaderContainerStyle={{ height: '300px' }} />
                : isUserAddressesExist ? <div className="d-grid grid-colt-auto-336 gap-24 w-full">
                    {userAddressList?.map((address) => {
                        return (

                            <>
                                <div className='border-green radi-10 p-12 d-flex flex-row space-between' >

                                    <div className=' d-flex flex-col  position-rel gap-8' >
                                        <span className="fs-12 dark-color">

                                            <span className="green-color">
                                                Address :-
                                            </span>

                                            {address?.full_address}

                                        </span>

                                        <span className="fs-12 dark-color">

                                            <span className="green-color">
                                                City :-
                                            </span>

                                            {address?.city}

                                        </span>

                                        <span className="fs-12 dark-color">

                                            <span className="green-color">
                                                Postal Code :-
                                            </span>

                                            {address?.post_code}

                                        </span>
                                    </div>

                                    <div onClick={() => setDeleteModal({ status: true, id: address?.id })} className="d-flex p-4 align-center hover-green transition-03 cursor-p justify-center  w-fit">
                                        <i className="ri-delete-bin-6-line"></i>
                                    </div>



                                </div>
                            </>

                        )
                    })}
                </div>
                    : <NoAddressLoader />
            }

            {deleteModal.status &&
                <Popup
                onClose={()=>(setDeleteModal(false))}
                    children={<PermissionPopup title='Are you want to remove address ?' onClickNo={() => setDeleteModal({ status: false, id: null })} onClickYes={() => fnDeleteAddress()} />}
                />
            }
            {addressPopup &&
                <Popup
                    className={'d-flex align-center flex-col position-fixed shadow-4-0-2000-grey t-50p l-50p wax-w-820 gap-24 transform-50p white-back radi-4 z-2 p-24 min-w-800 sm-min-w-360'}
                    children={<DawaAutoAddress placeholder={'Search your address'} setAddressPopup={setAddressPopup} />}
                    onClose={()=> setAddressPopup(false)}
                />
            }

        </div>
    )
}

export default Addresses
const styles = {
    btn: { backgroundColor: 'var(--green-color)', border: 'none', color: 'var(--white-color)', width: 120, borderRadius: 5, fontSize: 12, cursor: 'pointer' }
}