import React, { useState, useEffect } from 'react'
import { useAddUserMutation } from '../../redux/storeApis';
import InputField from '../InputField/InputField';
import { useSnackBarManager } from '../../lib/customHooks/useSnackBarManager';
import { BtnLoader } from '../loaders/Loaders';

const RegisterPopup = ({ setRegisterPopup, setLoginPopup }) => {

    const { fnShowSnackBar } = useSnackBarManager();
    const [loader, setLoader] = useState(false);
    const [addUser] = useAddUserMutation();

    const [data, setData] = useState({
        name: "",
        email: "",
        mobile: "",
        password: "",
        password_confirmation: "",
    });
    const [error, setError] = useState(null);
    const [validationError, setValidationError] = useState({});
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    useEffect(() => {
        fnShowSnackBar(validationError.name || validationError.email || validationError.mobile || validationError.password || validationError.password_confirmation)
    }, [validationError]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    const validateForm = () => {
        const errors = {};
        if (!data?.name.trim()) {
            errors.name = "Name is required";
        }
        if (!data.email.trim()) {
            errors.email = "Email is required";
        } else if (!isValidEmail(data?.email)) {
            errors.email = "Invalid email format";
        }
        if (data?.password.length < 6) {
            errors.password = "Password must be at least 6 characters";
        }
        if (data?.password !== data?.password_confirmation) {
            errors.password_confirmation = "Passwords do not match";
        }
        if (!data?.mobile.trim() && !data.mobile) {
            errors.mobile = "Number is required";
        }
        return errors;
    };
    const isValidEmail = (email) => {
        return email.includes("@");
    };
    const handleSubmit = async (e) => {
        e.preventDefault();

        const validationErrors = validateForm();
        if (Object.keys(validationErrors).length > 0) {
            setValidationError(validationErrors);
            // setShowSnackBar(true);
        } else {
            setValidationError({});
            try {
                setLoader(true);
                const response = await addUser({
                    name: data.name,
                    email: data.email,
                    mobile: "+45 " + data.mobile,
                    password: data.password,
                    password_confirmation: data.password_confirmation,
                });
                if (response.data.message === "Registration Successfully") {
                    // setLocalStorage(Config.userToken, response?.data?.token);
                    setLoader(false);
                    setRegisterPopup(false);
                    setLoginPopup(true);
                } else {
                    setError(response.data.message);
                    setLoader(false);
                }
            } catch (error) {
                setError("An error occurred. Please try again.");
                setLoader(false);
            }
        }
    };
    return (
        <div className='d-flex flex-col align-center gap-24 position-r p-12'>

            <i onClick={() => setRegisterPopup(false)} className="fa-solid fs-20 dark-color fa-xmark position-abs r-20 transition-03 hover-green cursor-p t-20" />

            <h2 className="fs-32 fw-500 dark-color">
                Register
            </h2>

            <div className="d-flex align-start flex-col gap-12 w-420 w-full">
                <span className="fs-14 dark-color">
                    Full Name
                    <span className="fs-14 green-color fw-500">
                        *
                    </span>
                </span>
                <InputField placeholder="Name" type="text" autofill="new-password" onChange={handleInputChange} name="name" value={data.name} />
            </div>

            <div className="d-flex align-start flex-col gap-12 w-420 w-full">
                <span className="fs-14 dark-color">
                    Email
                    <span className="fs-14 green-color fw-500">
                        *
                    </span>
                </span>
                <InputField
                    placeholder="Email"
                    type="email"
                    autofill="new-password"
                    onChange={handleInputChange}
                    name="email"
                    value={data.email}
                />
            </div>

            <div className="d-flex align-start flex-col gap-12 w-420 w-full">
                <span className="fs-14 dark-color">
                    Phone Number
                    <span className="fs-14 green-color fw-500">
                        *
                    </span>
                </span>
                <InputField
                    placeholder="Number"
                    type="number"
                    autofill="new-password"
                    onChange={handleInputChange}
                    name="mobile"
                    value={data.mobile}
                />
            </div>

            <div className="d-flex align-start flex-col gap-12 w-420 w-full">
                <span className="fs-14 dark-color">
                    Password
                    <span className="fs-14 green-color fw-500">
                        *
                    </span>
                </span>
                <InputField
                    placeholder="Password"
                    type="password"
                    autofill="new-password"
                    onChange={handleInputChange}
                    name="password"
                    value={data.password}
                    togglePasswordVisibility={togglePasswordVisibility}
                    showPassword={showPassword}
                />
            </div>

            <div className="d-flex align-start flex-col gap-12 w-420 w-full">
                <span className="fs-14 dark-color">
                    Confirm Password
                    <span className="fs-14 green-color fw-500">
                        *
                    </span>
                </span>
                <InputField
                    placeholder="Password"
                    type="password"
                    autofill="new-password"
                    onChange={handleInputChange}
                    name="password_confirmation"
                    value={data.password_confirmation}
                    togglePasswordVisibility={togglePasswordVisibility}
                    showPassword={showPassword}
                    placeholderColor="#fff"
                />
            </div>

            <span onClick={handleSubmit} className="d-flex p-8-12 green-back white-color cursor-p align-center justify-center w-full">
                {loader ? <BtnLoader /> : "Register"}
            </span>

            <span onClick={() => { setRegisterPopup(false); setLoginPopup(true) }} className="fs-16 dark-color cursor-p text-upper transition-03 hover-op06">
                Or Login
            </span>

        </div>
    )
}

export default RegisterPopup;