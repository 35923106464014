import React, { useState, useEffect } from "react";
import { Config } from "../../constants/Index";
import InputField from "../InputField/InputField";
import { useDispatch } from "react-redux";
import { setSelectedLoginUser } from "../../redux/UserReducer";
import { setLocalStorage } from "../localStorage";
import { useSnackBarManager } from "../../lib/customHooks/useSnackBarManager";
import { BtnLoader } from "../loaders/Loaders";
import DZText from "../text/DZText";
import { useLoginUserMutation } from "../../redux/storeApis";

const LoginPopup = ({
  setLoginPopup,
  setRegisterPopup,
  setForgetPasswordPopup = false,
  setMobileNavBarClass,
}) => {
  const dispatch = useDispatch();
  const { fnShowSnackBar } = useSnackBarManager();
  const [ loginUser ] = useLoginUserMutation();

  const [data, setData] = useState({ email: "", password: "" });
  const [loader, setLoader] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        handleClosePopups();
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  function handleOpenPopups() {
    setForgetPasswordPopup(true);
    setLoginPopup(false);
  }

  function handleClosePopups() {
    setLoginPopup(false);
  }

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const isValidEmail = (email) => {
    return email.includes("@");
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    if (data?.email && data?.password) {
      try {
        setLoader(true);
        const { data: responseData } = await loginUser(data).unwrap();
        const fetchUserToken = responseData?.access_token;
        setLocalStorage(Config.userToken, fetchUserToken);
        dispatch(setSelectedLoginUser(fetchUserToken));
        setLoginPopup(false);
        if(setMobileNavBarClass) { setMobileNavBarClass('d-none'); }
      } catch (error) {
        fnShowSnackBar(error.message || 'Something went wrong');
      } finally {
        setLoader(false);
      }
    } else {
      if (!data.email || !data.password) {
        fnShowSnackBar('Fill the required Field!');
      } else if (!isValidEmail(data.email)) {
        fnShowSnackBar('You entered wrong credentials');
      }
    }
  };

  // const handleLogin = async (e) => {

  //   const body = { email: data?.email, password: data?.password };
  //   loginUser(body).unwrap().then((payload)=>{
      
  //     console.log('payload', payload);

  //   }).catch(error => console.log(error))

  //   // e.preventDefault();
  //   // if (data.email && data.password) {
  //   //   setLoader(true);
  //   //   const response = await fetch(`${Config.serverUrl}api/login`, {
  //   //     method: "POST",
  //   //     headers: { "Content-Type": "application/json" },
  //   //     body: JSON.stringify({ email: data?.email, password: data?.password }),
  //   //   });

  //   //   if (response.ok) {
  //   //     const responseData = await response.json();
  //   //     if (responseData.message === "successfully") {
  //   //       const fetchUserToken = responseData?.data?.access_token;
  //   //       setLocalStorage(Config.userToken, fetchUserToken);
  //   //       dispatch(setSelectedLoginUser(fetchUserToken));
  //   //       setLoginPopup(false);
  //   //     } else {
  //   //       fnShowSnackBar(responseData.message || "Something went wrong");
  //   //     }
  //   //   } else {
  //   //     fnShowSnackBar("Something went wrong");
  //   //   }
  //   // } else {
  //   //   if (!data.email || !data.password) {
  //   //     fnShowSnackBar("Fill the required Field!");
  //   //   } else if (!isValidEmail(data.email)) {
  //   //     fnShowSnackBar("You enter wrong credentials");
  //   //   }
  //   // }
  //   // setLoader(false);
  // };

  return (
    <div className="d-flex flex-col align-center gap-24 position-r p-12 sm-max-w-300">
      <i
        onClick={handleClosePopups}
        className="fa-solid fs-20 dark-color fa-xmark position-abs r-20 transition-03 hover-green cursor-p t-20"
      ></i>
      <h2 className="fs-32 fw-500 dark-color">Login</h2>
      <div className="d-flex align-start flex-col gap-12 w-420 sm-max-w-300 w-full">
        <span className="fs-14 dark-color">
          <DZText children={'Common.username_or_email_address'} />
          <span className="fs-14 green-color fw-500">*</span>
        </span>
        <InputField
          placeholder="Email"
          type="email"
          onChange={handleInputChange}
          name="email"
          value={data.email}
        />
      </div>
      <div className="d-flex align-start flex-col gap-12 w-420 sm-max-w-300 w-full">
        <span className="fs-14 dark-color">
          <DZText children={'Common.password'} />
          <span className="fs-14 green-color fw-500">*</span>
        </span>
        <InputField
          placeholder="Password"
          type="password"
          onChange={handleInputChange}
          name="password"
          value={data.password}
          showPassword={showPassword}
          togglePasswordVisibility={togglePasswordVisibility}
        />
      </div>
      <div className="d-flex align-center gap-12 w-full space-between sm-flex-col">
        <div className="d-flex align-center gap-4">
          <input type="checkbox" />
          <label htmlFor="" className="fs-14 dark-color">
            <DZText children={'Common.remember_me'} />
          </label>
        </div>
        <span
          onClick={handleOpenPopups}
          className="fs-14 green-color cursor-p"
        >
          <DZText children={'Common.forget_your_password?'} />
        </span>
      </div>
      <span
        onClick={handleLogin}
        className="d-flex p-8-12 green-back white-color cursor-p align-center justify-center w-full"
      >
        {loader ? <BtnLoader /> : "Login"}
      </span>
      <span
        onClick={() => {
          setLoginPopup(false);
          setRegisterPopup(true);
        }}
        className="fs-16 dark-color cursor-p text-upper transition-03 hover-op06"
      >
        Or Create an Account
      </span>
    </div>
  );
};

export default LoginPopup;
