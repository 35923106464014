import React, { useEffect, useState } from 'react'
import { useGetAllOrdersListQuery } from '../../redux/storeApis'
import { Config } from '../../constants/Index';
import { getDate_yyyy_mm_dd } from '../../services/dictionary';
import { Loader, NoOrderLoader } from '../loaders/Loaders';
import OrderInfoProduct from '../orderInfoProduct/OrderInfoProduct';

const ViewOrderInfo = () => {

    const { data: allOrdersData, isLoading: isLoadingOrderDetails, refetch: refetchOrderDetails } = useGetAllOrdersListQuery();

    const allOrdersDetail = allOrdersData?.user_order;

    const isOrdersExist = allOrdersDetail?.length >= 1;

    const [detailModal, setDetailModal] = useState(false);
    const [selectedOrderDetail, setSelectedOrderDetail] = useState(null);

    useEffect(() => { refetchOrderDetails() }, []);

    const fnViewDetail = (detail) => {
        setDetailModal(true);
        setSelectedOrderDetail(detail);
    };


    return (
        <div className="d-flex gap-24 flex-col align-center w-full">

            <span className="fs-24 fw-600 dark-color">Order Info</span>

            {
                isLoadingOrderDetails ? <Loader svgClassName={"w-60 h-60"} loaderContainerStyle={{ height: '300px' }} />
                    : isOrdersExist ?
                        allOrdersDetail?.map((detail) => {
                            return (
                                <div className="d-flex flex-col w-full">

                                    <div className="border-green radi-10 d-flex flex-col gap-8 position-rel p-12">

                                        <span className="fs-18 fw-600 dark-color">
                                            Order Id {detail?.id}
                                        </span>

                                        <span className="fs-12 dark-color">

                                            <span className="green-color">
                                                Price :-
                                            </span>

                                            {Config.showCurrency(detail?.amount)}

                                        </span>

                                        <span className="fs-12 dark-color">

                                            <span className="green-color">
                                                Date :-
                                            </span>

                                            {getDate_yyyy_mm_dd(detail?.created_at)}

                                        </span>

                                        <span className="fs-12 dark-color">

                                            <span className="green-color">
                                                Status :-
                                            </span>

                                            {detail?.order_status}

                                        </span>

                                        <span onClick={() => fnViewDetail(detail)} className="fs-14 dark-color position-abs r-10 b-10 cursor-p" style={{ textDecoration: 'underline' }} >

                                            <span className="green-color">
                                                View :-
                                            </span>

                                            Detail

                                        </span>

                                    </div>

                                </div>
                            )
                        })
                        : <NoOrderLoader />
            }

            {detailModal && <OrderInfoProduct orderDetail={selectedOrderDetail} onClose={() => setDetailModal(false)} />}

        </div>
    )
}

export default ViewOrderInfo