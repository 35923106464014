import { useEffect, useState } from "react"
import { useGetCategoriesQuery, useGetProductsQuery, useGetSizesQuery } from "../../redux/storeApis";
import { KEYS, PRODUCT_SIZES, SORTING_TYPE } from "../../constants/Index";
import { Config } from "../../constants/Index";
import { useLocation } from "react-router-dom";
import { getLocalStorage } from "../../components/localStorage";

export function useShopPageManager() {
    const location = useLocation();
    const { data: allCategories, isLoading: isLoadingCategories } = useGetCategoriesQuery();
    const { data: allProducts, isLoading: isLoadingProducts } = useGetProductsQuery();
    const { data: allSizes, isLoading: isLoadingSizes } = useGetSizesQuery();

    const selectedSortingType = getLocalStorage(KEYS.sort_type);
    const selectedLanguage = getLocalStorage(KEYS.lang);

    const [selectedCategoryId, setSelectedCategoryId] = useState(1);
    const [showProductsLength, setShowProductsLength] = useState(50);
    const [sortingType, setSortingType] = useState(selectedSortingType ?? SORTING_TYPE.DEFAULT);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchResults, setSearchResults] = useState();
    const [searchQuery, setSearchQuery] = useState(location?.state?.query);
    const [searchLoader, setSearchLoader] = useState(false);

    const [productSize, setProductSize] = useState(PRODUCT_SIZES.ALL);

    useEffect(() => { setSearchResults(allProducts) }, [allProducts]);

    const selectedSizeClassName = 'fs-14 fw-400 white-color';
    const unSelectedSizeClassName = 'fs-14 fw-400 dark-color';

    const selectedBtnSizeClassName = "d-flex align-center w-fit p-4-8 green-back text-center cursor-p sm-min-w-fit";
    const unSelectedBtnSizeClassName = "d-flex align-center w-fit light-back text-center p-4-8 cursor-p sm-min-w-fit";

    const allSizesTitle = allSizes?.map((size) => size?.title?.en);

    const showAllSizes = [...new Set(allSizesTitle)];

    const lastIndex = currentPage * showProductsLength;
    const firstIndex = lastIndex - showProductsLength;

    const totalProducts = selectedCategoryId == 1 ? searchResults
        : searchResults?.filter((prod) => prod?.category_id == selectedCategoryId);

    const productsSizeCheck = productSize == PRODUCT_SIZES.ALL ? totalProducts
        : totalProducts?.filter((prod) => prod?.prices?.find((price) => price?.size?.title?.en == productSize));

    const sortedAllProducts = Config.fnGetSortedArray({
        type: sortingType, productSize: productSize, data: productSize == PRODUCT_SIZES.ALL ? searchResults : searchResults?.filter((prod) => prod?.prices?.find((price) => price?.size?.title?.en == productSize))
    });

    const allMinimumProducts = sortedAllProducts?.slice(firstIndex, lastIndex);

    const allMinimumProductsWithCategory = sortedAllProducts?.filter((prod) => prod?.category_id == selectedCategoryId)?.slice(firstIndex, lastIndex);

    const filterProducts = (selectedCategoryId == 1 ? allMinimumProducts : allMinimumProductsWithCategory) ?? [];

    const totalPages = Math.ceil((productsSizeCheck?.length ?? 0) / showProductsLength);
    const isShowPagination = totalPages > 1;

    const fnOnChange = (e) => {
        const inputValue = e.target.value;
        if (inputValue) {
            setSearchQuery(e.target.value)
        } else {
            setSearchQuery("");
            setSearchResults(allProducts);
        }
    };

    const fnSubmit = (value) => {
        if (value) { setSearchQuery(""); setSearchResults(allProducts); }
        else {
            setSearchLoader(true);
            setTimeout(() => {
                const results = allProducts?.filter((product) => {
                    const title = product?.title?.[selectedLanguage]?.toLowerCase();
                    const includesQuery = title?.includes(searchQuery?.toLowerCase());
                    return includesQuery;
                });
                setSearchResults(searchQuery ? results : allProducts);
                setSearchLoader(false);
            }, 500);
        }
    };

    const fnSelectSize = (size) => {
        setCurrentPage(1);
        setProductSize(size);
    };

    return {
        allCategories,
        allProducts,
        allSizes,
        showAllSizes,
        selectedCategoryId,
        showProductsLength,
        sortingType,
        currentPage,
        lastIndex,
        firstIndex,
        totalProducts,
        allMinimumProducts,
        allMinimumProductsWithCategory,
        filterProducts,
        totalPages,
        isShowPagination,
        searchLoader,
        productSize,

        isLoadingProducts,
        isLoadingCategories,
        isLoadingSizes,
        searchQuery,

        selectedSizeClassName,
        unSelectedSizeClassName,
        selectedBtnSizeClassName,
        unSelectedBtnSizeClassName,

        setCurrentPage,
        setSortingType,
        setSelectedCategoryId,
        setShowProductsLength,
        setSearchQuery,
        setSearchLoader,
        setProductSize,

        fnOnChange,
        fnSubmit,
        fnSelectSize
    }
};