import React, { useEffect, useRef } from 'react'

const Popup = ({ children, className, onClose }) => {
    const popupRef = useRef(null);

    useEffect(() => {
       if(onClose) {
        const handleOutsideClick = (event) => {
            if (popupRef.current && !popupRef.current.contains(event.target)) { onClose(); }
        };
        document.addEventListener('mousedown', handleOutsideClick);

        return () => { document.removeEventListener('mousedown', handleOutsideClick); };
       }
    }, [onClose]);

    return (
        <>
            <div className="popup"></div>
            <div ref={popupRef} className={`d-flex flex-col position-fixed shadow-4-0-2000-grey t-50p l-50p wax-w-820 gap-12 transform-50p white-back radi-4 z-2 p-16 ${className}`}>
                {children}
            </div>
        </>
    )
}

export default Popup;