import React from 'react'
import DZText from '../../../../components/text/DZText'

const NoProduct = () => {
    return (
        <div className="d-flex align-center justify-center gap-12 flex-col h-4vh">
            <i className="fa-solid fa-inbox dark-color fs-64 opacity-02"></i>
            <span className="fs-14 dark-color opacity-03">
                <DZText children={'Cart.no_product_in_this_category'} />
            </span>
        </div>
    )
}

export default NoProduct