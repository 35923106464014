import React, { useState } from 'react'
import { getLocalStorage } from '../localStorage';
import { Config, KEYS } from '../../constants/Index';
import { useAddToCartManager } from '../../lib/customHooks/useAddToCartManager';
import DZText from '../text/DZText';
import { useSnackBarManager } from '../../lib/customHooks/useSnackBarManager';
import useCartManager from '../../lib/customHooks/useCartManager';

const ProductPopup = ({ setIsShowPopUp }) => {

  const { fnAddToCart } = useAddToCartManager();
  const { fnGetProductQuantity } = useCartManager();
  const { fnShowSnackBar } = useSnackBarManager();

  const prod = JSON.parse(getLocalStorage(KEYS.prod_popup));
  const prod_name = prod?.title;
  const prod_detail = prod?.detail;
  const prod_img_link = Config.getProductImage(prod?.image);
  const prod_price = Config.showCurrency(prod?.price);
  const categoryName = prod?.category_name;

  const count = fnGetProductQuantity(prod?.id, prod?.size);

  const [quantity, setQuantity] = useState(count ? count : 1);

  const fnProductAddToCart = () => {
    const calculateQuantity = quantity - count;
    fnShowSnackBar('Product added into cart');
    setIsShowPopUp(false);
    fnAddToCart(prod, calculateQuantity);
  };

  return (
    <div className='d-flex align-center gap-32 p-10 position-rel  sm-flex-col min-w-fit' style={{ userSelect:'none' }}>

      <i onClick={() => setIsShowPopUp(false)} className="fs-20 ri-close-line position-abs t-0 r-0 cursor-p hover-green transition-03"></i>

      <div className="d-flex sm-h-280 border-grey sm-border-0 p-12 max-w-450 w-full" style={{overflow: 'hidden', display: 'flex', alignItems: 'center', justifyContent: 'center', minWidth: '240px' }}>
        <img src={prod_img_link} alt="" style={{ maxHeight: '60dvh', width: '100v%'}} className='' />
      </div>

      <div className="d-flex align-start flex-col gap-8 min-w-300">

        {/* Category */}

        <span className="fs-14 fw-600 green-color">
          <DZText children={categoryName} />
        </span>

        {/* Product Name */}

        <h3 className="fs-24 fw-600 dark-color">
          <DZText>{prod_name}</DZText>
        </h3>

        {/* Description */}

        <span className="fs-16 fw-400 dark-color">
          <DZText>{prod_detail}</DZText>
        </span>

        {/*  Free Delivery */}

        {/* <span className="p-8-12 text-center w-full fs-14 fs-400 light-back">
          Free delivery
        </span> */}

        {/*  Price */}

        <div className="d-flex align-center gap-16">

          <span className="fs-24 fw-500 green-color">
            {prod_price}
          </span>

          {/* <div className="d-flex align-center gap-2">
            {prod_stars}
            <svg viewBox="64 64 896 896" focusable="false" data-icon="star" width="1em" height="1em" fill="var(--coffee-color)" className='w-16 h-16' aria-hidden="true"><path d="M908.1 353.1l-253.9-36.9L540.7 86.1c-3.1-6.3-8.2-11.4-14.5-14.5-15.8-7.8-35-1.3-42.9 14.5L369.8 316.2l-253.9 36.9c-7 1-13.4 4.3-18.3 9.3a32.05 32.05 0 00.6 45.3l183.7 179.1-43.4 252.9a31.95 31.95 0 0046.4 33.7L512 754l227.1 119.4c6.2 3.3 13.4 4.4 20.3 3.2 17.4-3 29.1-19.5 26.1-36.9l-43.4-252.9 183.7-179.1c5-4.9 8.3-11.3 9.3-18.3 2.7-17.5-9.5-33.7-27-36.3z"></path></svg>

          </div> */}

        </div>

        {/*  Quantity */}

        <div className="d-flex align-center justify-center mt-3">

          <div className="d-flex align-center justify-center radi-4 border-grey">

            <div className="d-flex align-center justify-center h-54 p-0-20 w-30 border-r-1-grey ">
              <span className="fs-14 fw-600 dark-color text-center">
                {quantity}
              </span>
            </div>

            <div className="d-flex flex-col">

              <span onClick={() => setQuantity((pre) => pre + 1)} className="fs-18 fw-600 dark-color text-center cursor-p hover-green transition-03 border-b-1-grey p-0-8">
                +
              </span>

              <span onClick={() => quantity > 1 && setQuantity((pre) => pre - 1)} className="fs-18 fw-600 dark-color text-center cursor-p hover-green transition-03 p-0-8">
                -
              </span>

            </div>

          </div>

        </div>

        {/*  Add To Cart */}

        <button onClick={() => fnProductAddToCart()} className="cursor-p hover-white hover hover-green-back transtion-03 transition-03 mt-2 border-green fw-600 green-color w-full p-8-12 background-trans radi-24">
          <DZText upperCase children={'Common.add_to_cart'} />
        </button>

        {/*  Add To Compare */}

        {/* <button className="cursor-p hover-white hover hover-green-back transtion-03 transition-03 border-green fw-600 green-color w-full p-8-12 background-trans radi-24">
          ADD TO COMPARE
        </button> */}

      </div>

    </div >
  )
}

export default ProductPopup;